<template>
  <v-app>
    <v-dialog
        v-model="dialog"
        persistent
        max-width="600px"
        min-width="360px"
    >
      <v-card class="px-4">
        <v-card-text>
          <v-form ref="setupForm" v-model="valid" lazy-validation>
            <v-row>
              <v-col cols="12">
                <span>
                  <b>Twee factor authenticatie</b><br>
                  Scan de QR code met uw authenticator app (bijv. Authy, Google Authenticator, LastPass, Microsoft Authenticator, ...)<br>
                  </span>
              </v-col>
              <v-col cols="12">
                <v-img
                    src="/api/auth/token/setup2fa"
                    height="200"
                    width="200"
                ></v-img>
              </v-col>
              <v-col cols="12">
                <v-text-field autofocus id="secondfactor" v-model="secondfactor" :rules="secondfactorRules" label="2FA code" autocomplete="2fa" required></v-text-field>
              </v-col>
              <v-col class="d-flex" cols="12" sm="6" xsm="12">
                <span class="red--text" v-if="error">{{ error }}</span>
              </v-col>
              <v-spacer></v-spacer>
              <v-col class="d-flex" cols="12" sm="3" xsm="12" align-end>
                <v-btn id="submit" x-large block :disabled="!valid" color="success" @click="validate"> Submit </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-app>
</template>
<script>

export default {
  components: {
  },

  name: 'Setup2FA',
  data: () => ({
    dialog: true,
    secondfactor: "",
    valid: true,
    error: "",
    secondfactorRules: [
      v => !!v || "Verplicht",
      v => /[0-9]{6}/.test(v) || "6 cijfers"
    ],
  }),

  methods: {
    validate: function() {
      if (this.$refs.setupForm.validate()) {
        console.log("sending", this.secondfactor)
        const requestOptions = {
          method: "POST",
          headers: {"Content-Type": "application/json"},
          credentials: 'same-origin',
          body: JSON.stringify({code: this.secondfactor})
        };
        fetch("/api/auth/token/setup2fa", requestOptions)
            .then(response => {
              if (response.ok) {
                console.log("setup2fa(): response ok:", response)
                this.$router.push(this.$route.query.redirect || '/').catch((e) => {
                  console.log("redirect error:", e)
                });
              } else {
                this.error = "ongeldige code"
                console.log(response)
              }
            })
      }
    },
  }
};
</script>