<template>
  <v-app>
    <v-dialog
        hide-overlay
        persistent
        v-model="dialog"
        max-width="600px"
        min-width="360px"
    >
      <div>
        <v-tabs
            v-model="tab"
            show-arrows
            background-color="primary"
            icons-and-text
            dark
            grow
            slider-color="#D27619"
            slider-size="4"
        >
          <v-tab v-for="i in tabs" :key="i.name">
            <v-icon large>{{ i.icon }}</v-icon>
            <div class="caption py-1">{{ i.name }}</div>
          </v-tab>
          <v-tab-item>
            <v-card class="px-4">
              <v-card-text>
                <v-form ref="loginForm" v-model="valid" lazy-validation>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field id="login_call" v-model="loginCall" :rules="loginCallRules" label="Call" autocomplete="username" required></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field id="login_password" v-model="loginPassword" :append-icon="show1?'mdi-eye':'mdi-eye-off'" :rules="[rules.required, rules.min]" :type="show1 ? 'text' : 'password'" name="input-10-1" label="Password" hint="At least 8 characters" counter @click:append="show1 = !show1" autocomplete="current-password"></v-text-field>
                    </v-col>
                    <v-col class="d-flex" cols="12" sm="6" xsm="12">
                      <span class="red--text" v-if="loginError">{{ loginError }}</span>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col class="d-flex" cols="12" sm="3" xsm="12" align-end>
                      <v-btn id="login_submit" x-large block :disabled="!valid" color="success" @click="validate"> Login </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card class="px-4">
              <v-card-text>
                <v-form ref="registerForm" v-model="valid" lazy-validation>
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field id="register_call" v-model="call" :rules="[rules.required]" label="Call" maxlength="20" required></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field id="register_email" v-model="email" :rules="emailRules" label="E-mail" required></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field id="register_password1" v-model="password" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" :rules="[rules.required, rules.min]" :type="show1 ? 'text' : 'password'" name="input-10-1" label="Password" hint="At least 8 characters" counter @click:append="show1 = !show1"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field id="register_password2" block v-model="verify" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" :rules="[rules.required, passwordMatch]" :type="show1 ? 'text' : 'password'" name="input-10-1" label="Confirm Password" counter @click:append="show1 = !show1"></v-text-field>
                    </v-col>
                    <v-col class="d-flex" cols="12" sm="6" xsm="12">
                      <span class="red--text" v-if="registerError">{{ registerError }}</span>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col class="d-flex ml-auto" cols="12" sm="3" xsm="12">
                      <v-btn id="register_submit" x-large block :disabled="!valid" color="success" @click="validate">Register</v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </div>
    </v-dialog>
  </v-app>
</template>

<script>
export default {
  name: "LoginView",
  computed: {
    passwordMatch() {
      return () => this.password === this.verify || "Password must match";
    }
  },
  methods: {
    validate() {
      if (this.$refs.loginForm.validate()) {
        console.log("validate() login with", this.loginCall, this.loginPassword)
        this.createToken()
      }
      if (this.$refs.registerForm.validate()) { // Cannot read properties of undefined (reading 'validate')
        console.log("validate() registration with", this.call, this.email)
        this.registerUser()
      }
    },

    reset() {
      this.$refs.form.reset();
    },

    resetValidation() {
      this.$refs.form.resetValidation();
    },

    createToken() {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        credentials: 'same-origin',
        body: JSON.stringify({ username: this.loginCall.toUpperCase(), password: this.loginPassword })
      };
      fetch("/api/auth/create", requestOptions)
          .then(response => {
            if (response.ok) {
              console.log("createToken(): logged in:", response)
              this.$router.push(this.$route.query.redirect || '/verify-2fa').catch((e) => {
                console.log("redirect error:", e)
              });
            } else if (response.status === 423) {
              this.loginError = "Uw account is tijdelijk disabled, gelieve contact op te nemen met de beheerder. info@pi6atv.com"
            } else {
              console.log(response)
              this.loginError = "kon niet inloggen"
            }
          })
    },

    registerUser() {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        credentials: 'same-origin',
        body: JSON.stringify({ call: this.call.toUpperCase(), email: this.email, password: this.password })
      };
      fetch("/api/auth/registerAccount", requestOptions)
          .then(response => {
            // FIXME
            console.log("registerUser(): ", response)
            if (response.ok) {
              this.$router.push('/pre-verify-email').catch((e) => {
                console.log("redirect error:", e)
              });
              return ""
            }
            return response.text()
          })
          .then((response) => {
            this.registerError = response
            console.log(response)
          })
    },
  },

  data: () => ({
    dialog: true,
    tab: 0,
    tabs: [
      {name:"Login", icon:"mdi-account"},
      {name:"Register", icon:"mdi-account-outline"}
    ],
    valid: true,
    loginError: "",
    registerError: "",

    call: "",
    email: "",
    password: "",
    verify: "",
    loginPassword: "",
    loginCall: "",
    loginCallRules: [
      v => !!v || "Verplicht",
      v => /[a-zA-Z0-9]+/.test(v) || "Ongeldige call"
    ],
    emailRules: [
      v => !!v || "Verplicht",
      v => /.+@.+\..+/.test(v) || "Ongeldig e-mail adres"
    ],

    show1: false,
    rules: {
      required: value => !!value || "Verplicht",
      min: v => (v && v.length >= 8) || "Min 8 karakters"
    }
  })
}
</script>

<style scoped>

</style>
