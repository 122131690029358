<template>
  <v-parallax
      height="960"
      dark
      src="@/assets/background.jpg"
  >
  </v-parallax>
</template>
<script>

export default {
  components: {
  },
  name: 'HomeView',
  data: () => ({
  }),
};
</script>
