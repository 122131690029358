<template>
  <v-parallax
      height="960"
      dark
      src="@/assets/background.jpg"
  >
    <v-row
        align="center"
        justify="center"
    >
      <v-col
          class="text-center"
          cols="4"
      >
        <v-card>
          <v-card-text>
              <v-row
                  align="center"
                  justify="center"
              >
                <v-col>
                  Om gebruik te kunnen maken van de PI6ATV Web-Control heeft u een Authenticator app nodig.<br><br>
                  Veel gebruikte apps zijn Google Authenticator, Microsoft Authenticator en Twilio Authy, maar ook andere authenticator apps zijn bruikbaar.<br><br>
                  Google Authenticator <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2">Android</a>&nbsp;
                  <a href="https://apps.apple.com/app/google-authenticator/id388497605">Apple</a><br><br>
                  Microsoft Authenticator <a href="https://play.google.com/store/apps/details?id=com.azure.authenticator">Android</a>&nbsp;
                  <a href="https://apps.apple.com/app/microsoft-authenticator/id983156458">Apple</a><br><br>
                  Twilio Authy <a href="https://play.google.com/store/apps/details?id=com.authy.authy">Android</a>&nbsp;
                  <a href="https://apps.apple.com/app/authy/id494168017">Apple</a>
                  <a href="https://authy.com/download">Windows</a>
                  <a href="https://authy.com/download">MacOS</a>
                  <a href="https://authy.com/download">Linux</a>
                  <br><br>
                  check uw email en volg daar verder de instructies op.<br>
                  <br>
                  U kunt dit venster nu sluiten
                </v-col>
              </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-parallax>
</template>
<script>

export default {
  components: {
  },
  name: 'VerifyEmailView',

  async mounted() {
    console.log("verifyEmail: route.query:", this.$route.query)
    if (this.$route.query.code !== undefined) {
      this.code = this.$route.query.code;
      this.submit()
    }
  },

  data: () => ({
    emailcode: "",
    dialog: true,
    valid: true,
    error: "",
    emailcodeRules: [
      v => !!v || "Verplicht",
      v => /.{6}/.test(v) || "6 karakters"
    ],
  }),

  methods: {
    validate() {
      if (this.$refs.emailcodeForm.validate()) {
        console.log("verifyEmail: submit: ", this.code)
        const requestOptions = {
          method: "POST",
          headers: {"Content-Type": "application/json"},
          credentials: 'same-origin',
          body: JSON.stringify({code: this.emailcode,})
        };
        fetch("/api/auth/token/emailVerification", requestOptions)
            .then(response => {
              if (response.ok) {
                console.log("verifyEmail(): response:", response)
                this.$router.push(this.$route.query.redirect || '/').catch((e) => {
                  console.log("redirect error:", e)
                });
              } else {
                this.error = "ongeldige code"
              }
            })
      }
    },
  },
};
</script>
