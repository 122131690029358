<template>
  <v-app>
    <v-dialog
        v-model="dialog"
        persistent
        max-width="600px"
        min-width="360px"
    >
      <v-card class="px-4">
        <v-card-text>
          <v-form ref="emailcodeForm" v-model="valid" lazy-validation>
            <v-row>
              <v-col cols="12">
                <span>
                  <b>Email Verificatie</b><br>
      We hebben we een code naar uw email adres gestuurd.<br>
      Vul de ontvangen code hieronder in ter verificatie van uw email adres:<br>
                </span>
              </v-col>
              <v-col cols="12">
                <v-text-field autofocus id="email code" v-model="emailcode" :rules="emailcodeRules" label="e-mail code" required></v-text-field>
              </v-col>
              <v-col class="d-flex" cols="12" sm="6" xsm="12">
                <span class="red--text" v-if="error">{{ error }}</span>
              </v-col>
              <v-spacer></v-spacer>
              <v-col class="d-flex" cols="12" sm="3" xsm="12" align-end>
                <v-btn id="submit" x-large block :disabled="!valid" color="success" @click="validate"> Submit </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-app>
</template>
<script>

export default {
  components: {
  },
  name: 'VerifyEmail',

  async mounted() {
    console.log("verifyEmail: route.query:", this.$route.query)
    if (this.$route.query.code !== undefined) {
      console.log("automatic submit")
      this.emailcode = this.$route.query.code;
      this.submit()
    }
  },

  data: () => ({
    emailcode: "",
    dialog: true,
    valid: true,
    error: "",
    emailcodeRules: [
      v => !!v || "Verplicht",
      v => /.{6}/.test(v) || "6 karakters"
    ],
  }),

  methods: {
    validate() {
      if (this.$refs.emailcodeForm.validate()) {
        this.submit()
      } else {
        console.log("failed validation")
      }
    },
    submit() {
      console.log("verifyEmail: submit: ", this.emailcode)
      const requestOptions = {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        credentials: 'same-origin',
        body: JSON.stringify({code: this.emailcode,})
      };
      fetch("/api/auth/token/emailVerification", requestOptions)
          .then(response => {
            if (response.ok) {
              console.log("verifyEmail(): response:", response)
              this.$router.push(this.$route.query.redirect || '/').catch((e) => {
                console.log("redirect error:", e)
              });
            } else {
              this.error = "ongeldige code"
            }
          })
    },
  },
};
</script>