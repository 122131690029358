<template>
  <div>
  <v-data-table
      :headers="headers"
      :items="logs"
      class="elevation-1"
      sort-by="created"
      :items-per-page="15"
      :sort-desc="true"
  >
  </v-data-table>
  </div>
</template>

<script>
export default {
  name: "AuditLogView",
  data: () => ({
    headers: [
      {
        text: 'Tijd',
        align: 'start',
        sortable: true,
        value: 'created',
      },
      { text: 'Call', value: 'call' },
      { text: 'IP', value: 'ip' },
      { text: 'Actie', value: 'action' },
      { text: 'Info', value: 'data' },
    ],
    logs: [],
    error: "",
  }),

  methods: {
    updateData() {
      fetch("/api/admin/auditlogs")
          .then(response => response.json())
          .then(data => {
            if (data["error"]) {
              this.error = data["error"]
              return
            }
            if (data === null) {
              this.error = "no data"
              return
            }
            this.logs = data;
          })
    },
  },
  async created() {
    this.updateData();
  },

}

</script>

<style scoped>

</style>