<template>
  <div>
    <v-card>
      <v-card-text>
        <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
        ></v-text-field>
      </v-card-text>
    </v-card>
  <v-data-table
      :headers="headers"
      :items="users"
      class="elevation-1"
      :search="search"
  >
    <template v-slot:[`item.username`]="{ item }">
      &lt;<span>{{ item.username }}</span>&gt;
    </template>
    <template v-slot:[`item.emailVerified`]="{ item }">
      <v-simple-checkbox
          v-model="item.emailVerified"
          disabled
      ></v-simple-checkbox>
    </template>
    <template v-slot:[`item.secondFactorVerified`]="{ item }">
      <v-simple-checkbox
          v-model="item.secondFactorVerified"
          disabled
      ></v-simple-checkbox>
    </template>
    <template v-slot:[`item.isDisabled`]="{ item }">
      <v-simple-checkbox
          :id="'disable_' + item.username"
          v-model="item.isDisabled"
          @click="updateDisabled(item.username)"
      ></v-simple-checkbox>
    </template>
    <template v-slot:[`item.isAdmin`]="{ item }">
      <v-simple-checkbox
          v-model="item.isAdmin"
          disabled
      ></v-simple-checkbox>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon
          small
          @click="deleteItem(item.username)"
      >
        mdi-delete
      </v-icon>
    </template>
  </v-data-table>
  <v-dialog v-model="dialogDelete" max-width="500px">
    <v-card>
      <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
        <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>

  </div>
</template>

<script>
export default {
  name: "ManageUsersView",
  data: () => ({
    search: "",
    dialogDelete: false,
    headers: [
      {
        text: 'Gebruikersnaam',
        align: 'start',
        sortable: true,
        value: 'username',
      },
      { text: 'E-mail', value: 'email' },
      { text: 'E-mail geverifieerd', value: 'emailVerified' },
      { text: '2FA ingesteld', value: 'secondFactorVerified' },
      { text: 'Is disabled', value: 'isDisabled' },
      { text: 'Is admin', value: 'isAdmin' },
      { text: '', value: 'actions', sortable: false },
    ],
    users: [],
    error: "",
    userToDelete: "",
  }),
  watch: {
    dialogDelete (val) {
      val || this.closeDelete()
    },
  },

  methods: {
    closeDelete () {
      this.userToDelete = ""
      this.dialogDelete = false
    },

    deleteItem (username) {
      this.userToDelete = username
      this.dialogDelete = true
    },

    deleteItemConfirm () {
      this.deleteUser(this.userToDelete)
      this.userToDelete = ""
      this.closeDelete()
    },

    deleteUser(username) {
      console.log("delete user", username)
      const requestOptions = {
        method: "DELETE",
      };

      fetch(encodeURI("/api/admin/users/"+username), requestOptions)
          .then(result => {
            if (!result.ok) {
              console.log("delete failed")
            }
            setTimeout(function() {this.updateData()}.bind(this), 300)
          })
      this.closeDelete()

    },
    updateDisabled(username) {
      console.log("update disabled for", username)

      const requestOptions = {
        method: "PUT",
      };

      fetch("/api/admin/users/" + username + "/disable", requestOptions)
          .then(result => {
            if (!result.ok) {
              console.log("disable user failed")
            }
            setTimeout(function() {this.updateData()}.bind(this), 300)
          })
      this.updateData();
    },
    updateData() {
      fetch("/api/admin/users/")
          .then(response => response.json())
          .then(data => {
            if (data["error"]) {
              this.error = data["error"]
              return
            }
            if (data === null) {
              this.error = "no data"
              return
            }
            this.users = data;
          })
    },
  },
  async created() {
    this.updateData();
  },

}

</script>

<style scoped>

</style>
