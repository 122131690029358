<template>
  <span>Logging out</span>
</template>

<script>
import Vue from "vue";

export default {
  name: "LogoutView",
  async created() {
    console.log("removing cookie")
    console.log(Vue.$cookies.remove("token"))
    console.log("cookie is now:", Vue.$cookies.get("token"))
    console.log("routing to /")
    this.$router.push("/").catch((e) => {
      console.log("redirect error:", e)
    });
  },
}
</script>

<style scoped>

</style>