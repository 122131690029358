<template>
  <iframe
      :src="this.$route.path"
      height="100%"
      width="100%"
  ></iframe>
</template>
<script>

export default {
  components: {
  },
  name: 'FrameView',
  data: () => ({
  }),
};
</script>
